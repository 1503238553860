import "./App.css";
import Index from "./pages/Index";
import AdoptDog from "./pages/AdoptDog";
import AdoptCat from "./pages/AdoptCat";
import Contact from "./pages/Contact";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/usvoji-psa" element={<AdoptDog />} />
          <Route path="/usvoji-macku" element={<AdoptCat />} />
          <Route path="/kontakt" element={<Contact />} />
        </Routes>
      </Router>

      {/* <Index /> */}
    </div>
  );
}

export default App;
