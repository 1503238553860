import React, { Fragment } from "react";
import Menu from "./Menu";
import Footer from "./Footer";

function AdoptDog() {
  let empty_url=""
  return (
    <Fragment>
      <Menu />

      <main>
        {/* <!-- breadcrumb-area --> */}
        <section
          className="breadcrumb-area breadcrumb-bg"
          style={{ backgroundImage: `url("assets/img/bg/breadcrumb_bg.jpg")`}}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-content">
                  <h2 className="title">Dog List</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Dog List
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- breadcrumb-area-end --> */}

        {/* <!-- adoption-shop-area --> */}
        <section className="adoption-shop-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9">
                <div className="section-title text-center mb-65">
                  <div className="section-icon">
                    <img src="assets/img/icon/pawprint.png" alt="" />
                  </div>
                  <h5 className="sub-title">Upoznaj pse</h5>
                  <h2 className="title">Psi koji čekaju na usvojenje</h2>
                  {/* <p>
                    The best overall dog DNA test is Embark Breed & Health Kit
                    (view at Chewy), which provides you with a breed brwn and
                    information Most dogs
                  </p> */}
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="adoption-shop-item">
                  <div className="adoption-shop-thumb">
                    <img src="assets/img/product/adoption_shop_thumb01.jpg" alt="" />
                    <a href="shop-details.html" className="btn">
                      Adoption <img src="assets/img/icon/w_pawprint.png" alt="" />
                    </a>
                  </div>
                  <div className="adoption-shop-content">
                    <h4 className="title">
                      <a href="shop-details.html">Mister Tartosh</a>
                    </h4>
                    <div className="adoption-meta">
                      <ul>
                        <li>
                          <i className="fas fa-cog"></i>
                          <a href={empty_url}>Siberian Husky</a>
                        </li>
                        <li>
                          <i className="far fa-calendar-alt"></i> Birth : 2021
                        </li>
                      </ul>
                    </div>
                    <div className="adoption-rating">
                      <ul>
                        <li className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="price">
                          Total Price : <span>Free</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="adoption-shop-item">
                  <div className="adoption-shop-thumb">
                    <img src="assets/img/product/adoption_shop_thumb02.jpg" alt="" />
                    <a href="shop-details.html" className="btn">
                      Adoption <img src="assets/img/icon/w_pawprint.png" alt="" />
                    </a>
                  </div>
                  <div className="adoption-shop-content">
                    <h4 className="title">
                      <a href="shop-details.html">Charlie</a>
                    </h4>
                    <div className="adoption-meta">
                      <ul>
                        <li>
                          <i className="fas fa-cog"></i>
                          <a href={empty_url}>Golden Retriever</a>
                        </li>
                        <li>
                          <i className="far fa-calendar-alt"></i> Birth : 2020
                        </li>
                      </ul>
                    </div>
                    <div className="adoption-rating">
                      <ul>
                        <li className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="price">
                          Total Price : <span>$30</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="adoption-shop-item">
                  <div className="adoption-shop-thumb">
                    <img src="assets/img/product/adoption_shop_thumb03.jpg" alt="" />
                    <a href="shop-details.html" className="btn">
                      Adoption <img src="assets/img/icon/w_pawprint.png" alt="" />
                    </a>
                  </div>
                  <div className="adoption-shop-content">
                    <h4 className="title">
                      <a href="shop-details.html">Alessia Max</a>
                    </h4>
                    <div className="adoption-meta">
                      <ul>
                        <li>
                          <i className="fas fa-cog"></i>
                          <a href={empty_url}>German Sherped</a>
                        </li>
                        <li>
                          <i className="far fa-calendar-alt"></i> Birth : 2020
                        </li>
                      </ul>
                    </div>
                    <div className="adoption-rating">
                      <ul>
                        <li className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="price">
                          Total Price : <span>$29</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="adoption-shop-item">
                  <div className="adoption-shop-thumb">
                    <img src="assets/img/product/adoption_shop_thumb04.jpg" alt="" />
                    <a href="shop-details.html" className="btn">
                      Adoption <img src="assets/img/icon/w_pawprint.png" alt="" />
                    </a>
                  </div>
                  <div className="adoption-shop-content">
                    <h4 className="title">
                      <a href="shop-details.html">Canadian</a>
                    </h4>
                    <div className="adoption-meta">
                      <ul>
                        <li>
                          <i className="fas fa-cog"></i>
                          <a href={empty_url}>German Sherped</a>
                        </li>
                        <li>
                          <i className="far fa-calendar-alt"></i> Birth : 2021
                        </li>
                      </ul>
                    </div>
                    <div className="adoption-rating">
                      <ul>
                        <li className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="price">
                          Total Price : <span>$39</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="adoption-shop-item">
                  <div className="adoption-shop-thumb">
                    <img src="assets/img/product/adoption_shop_thumb05.jpg" alt="" />
                    <a href="shop-details.html" className="btn">
                      Adoption <img src="assets/img/icon/w_pawprint.png" alt="" />
                    </a>
                  </div>
                  <div className="adoption-shop-content">
                    <h4 className="title">
                      <a href="shop-details.html">Entertainment</a>
                    </h4>
                    <div className="adoption-meta">
                      <ul>
                        <li>
                          <i className="fas fa-cog"></i>
                          <a href={empty_url}>Siberian Husky</a>
                        </li>
                        <li>
                          <i className="far fa-calendar-alt"></i> Birth : 2021
                        </li>
                      </ul>
                    </div>
                    <div className="adoption-rating">
                      <ul>
                        <li className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="price">
                          Total Price : <span>Free</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="adoption-shop-item">
                  <div className="adoption-shop-thumb">
                    <img src="assets/img/product/adoption_shop_thumb06.jpg" alt="" />
                    <a href="shop-details.html" className="btn">
                      Adoption <img src="assets/img/icon/w_pawprint.png" alt="" />
                    </a>
                  </div>
                  <div className="adoption-shop-content">
                    <h4 className="title">
                      <a href="shop-details.html">Dangerous</a>
                    </h4>
                    <div className="adoption-meta">
                      <ul>
                        <li>
                          <i className="fas fa-cog"></i>
                          <a href={empty_url}>Golden Retriever</a>
                        </li>
                        <li>
                          <i className="far fa-calendar-alt"></i> Birth : 2021
                        </li>
                      </ul>
                    </div>
                    <div className="adoption-rating">
                      <ul>
                        <li className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </li>
                        <li className="price">
                          Total Price : <span>Free</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- adoption-shop-area-end --> */}

        {/* <!-- faq-area --> */}
        <section className="faq-area-two faq-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="faq-img-wrap">
                  <img
                    src="assets/img/images/faq_tv.png"
                    className="img-frame"
                    alt=""
                  />
                  <img
                    src="assets/img/images/faq_img.png"
                    className="main-img"
                    alt=""
                  />
                  <a
                    href="https://www.youtube.com/watch?v=XdFfCPK5ycw"
                    className="popup-video"
                  > </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="faq-wrapper">
                  <div className="section-title mb-35">
                    <h5 className="sub-title">FAQ Question</h5>
                    <h2 className="title">History & Family Adoption</h2>
                  </div>
                  <div className="accordion" id="accordionExample">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Working for dog adoption
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          The best overall dog DNA test is embark breed & health
                          Kit (view atths Chewy), which provides you with a
                          breed brwn and ition on provides ancestors most dogs.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Competitions & Awards
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          The best overall dog DNA test is embark breed & health
                          Kit (view atths Chewy), which provides you with a
                          breed brwn and ition on provides ancestors most dogs.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            The puppies are 3 months old
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          The best overall dog DNA test is embark breed & health
                          Kit (view atths Chewy), which provides you with a
                          breed brwn and ition on provides ancestors most dogs.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="faq-shape">
            <img src="assets/img/images/faq_shape.png" alt="" />
          </div>
        </section>
        {/* <!-- faq-area-end --> */}

        {/* <!-- breeds-services --> */}
        <section className="breeds-services pt-110 pb-110">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9">
                <div className="section-title text-center mb-65">
                  <div className="section-icon">
                    <img src="assets/img/icon/pawprint.png" alt="" />
                  </div>
                  <h5 className="sub-title">Service to Breeds</h5>
                  <h2 className="title">Most Popular Dog Breed</h2>
                  <p>
                    The best overall dog DNA test is Embark Breed & Health Kit
                    (view at Chewy), which provides you with a breed brwn and
                    information Most dogs
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="breed-services-active owl-carousel">
                  <div className="breed-services-item">
                    <div className="thumb">
                      <img src="assets/img/images/breed_services_img01.jpg" alt="" />
                    </div>
                    <div className="content">
                      <h3 className="title">
                        <a href="breeder-details.html">Golden Retriever</a>
                      </h3>
                    </div>
                  </div>
                  <div className="breed-services-item">
                    <div className="thumb">
                      <img src="assets/img/images/breed_services_img02.jpg" alt="" />
                    </div>
                    <div className="content">
                      <h3 className="title">
                        <a href="breeder-details.html">German Sharped</a>
                      </h3>
                    </div>
                  </div>
                  <div className="breed-services-item">
                    <div className="thumb">
                      <img src="assets/img/images/breed_services_img03.jpg" alt="" />
                    </div>
                    <div className="content">
                      <h3 className="title">
                        <a href="breeder-details.html">Siberian Husky</a>
                      </h3>
                    </div>
                  </div>
                  <div className="breed-services-item">
                    <div className="thumb">
                      <img src="assets/img/images/breed_services_img04.jpg" alt="" />
                    </div>
                    <div className="content">
                      <h3 className="title">
                        <a href="breeder-details.html">Bernes Mountain</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="breed-services-info"
                  style={{ backgroundImage: `url("assets/img/bg/breed_services_bg.jpg")`}}
                >
                  <h5 className="sub-title">Dog Breeder</h5>
                  <h3 className="title">Available for Breed</h3>
                  <p>
                    The best overall dog DNA test is Embark Breed & Health Kit
                    (view at Chewy), which provid dogs
                  </p>
                  <a href="shop.html" className="btn">
                    More Pets <img src="assets/img/icon/w_pawprint.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="breed-services-shape">
            <img src="assets/img/images/breed_services_shape01.png" alt="" />
          </div>
          <div className="breed-services-shape shape-two">
            <img src="assets/img/images/breed_services_shape02.png" alt="" />
          </div>
        </section>
        {/* <!-- breeds-services-end --> */}

        {/* <!-- newsletter-area --> */}
        <div className="newsletter-area pb-110">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="newsletter-wrap">
                  <div className="newsletter-content">
                    <h2 className="title">Newsletter For</h2>
                    <p>
                      <span>*</span> Do Not Show Your Email.
                    </p>
                  </div>
                  <div className="newsletter-form">
                    <form action="#">
                      <input type="email" placeholder="Enter Your Email..." />
                      <button type="submit" className="btn">
                        Subscribe
                      </button>
                    </form>
                  </div>
                  <div className="newsletter-shape">
                    <img src="assets/img/images/newsletter_shape01.png" alt="" />
                  </div>
                  <div className="newsletter-shape shape-two">
                    <img src="assets/img/images/newsletter_shape02.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- newsletter-area-end --> */}
      </main>

      <Footer />
    </Fragment>
  );
}

export default AdoptDog;
