import React, { Fragment } from "react";

function Menu() {
  let empty_url="";
  return (
    <Fragment>
      {" "}
      <header>
        <div id="sticky-header" className="menu-area">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler">
                  <i className="fas fa-bars"></i>
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav show">
                    <div className="logo">
                      <a href="index.html">
                        <img src="assets/img/logo/logo.png" alt="" />
                      </a>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li className="menu-item-has-children">
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/usvoji-psa">Usvoji psa</a>
                        </li>
                        <li>
                          <a href="/usvoji-macku">Usvoji mačku</a>
                        </li>
                        {/* <li className="menu-item-has-children">
                          <a href="shop.html">Shop</a>
                          <ul className="submenu">
                            <li>
                              <a href="shop.html">Our Shop</a>
                            </li>
                            <li>
                              <a href="shop-details.html">Shop Details</a>
                            </li>
                          </ul>
                        </li> */}
                        {/* <li className="active">
                          <a href="adoption.html">Adoption</a>
                        </li> */}
                        {/* <li className="menu-item-has-children">
                          <a href="breeder.html">Breeder</a>
                          <ul className="submenu">
                            <li>
                              <a href="breeder.html">Our Breeder</a>
                            </li>
                            <li>
                              <a href="breeder-details.html">Breeder Details</a>
                            </li>
                          </ul>
                        </li> */}
                        {/* <li className="menu-item-has-children">
                          <a href="blog.html">Blog</a>
                          <ul className="submenu">
                            <li>
                              <a href="blog.html">Our Blog</a>
                            </li>
                            <li>
                              <a href="blog-details.html">Blog Details</a>
                            </li>
                          </ul>
                        </li> */}
                        <li>
                          <a href="/kontakt">Kontakti</a>
                        </li>
                      </ul>
                    </div>
                    <div className="header-action d-none d-md-block">
                      <ul>
                        {/* <li className="header-search">
                          <a href={empty_url}>
                            <i className="flaticon-search"></i>
                          </a>
                        </li> */}
                        {/* <li className="header-shop-cart">
                          <a href={empty_url}>
                            <i className="flaticon-shopping-bag"></i>
                            <span>2</span>
                          </a>
                          <ul className="minicart">
                            <li className="d-flex align-items-start">
                              <div className="cart-img">
                                <a href={empty_url}>
                                  <img
                                    src="assets/img/product/cart_p01.jpg"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div className="cart-content">
                                <h4>
                                  <a href={empty_url}>The King Charles Spaniel</a>
                                </h4>
                                <div className="cart-price">
                                  <span className="new">$229.9</span>
                                  <span>
                                    <del>$229.9</del>
                                  </span>
                                </div>
                              </div>
                              <div className="del-icon">
                                <a href={empty_url}>
                                  <i className="far fa-trash-alt"></i>
                                </a>
                              </div>
                            </li>
                            <li className="d-flex align-items-start">
                              <div className="cart-img">
                                <a href={empty_url}>
                                  <img
                                    src="assets/img/product/cart_p02.jpg"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div className="cart-content">
                                <h4>
                                  <a href={empty_url}>The Labrador Retriever</a>
                                </h4>
                                <div className="cart-price">
                                  <span className="new">$229.9</span>
                                  <span>
                                    <del>$229.9</del>
                                  </span>
                                </div>
                              </div>
                              <div className="del-icon">
                                <a href={empty_url}>
                                  <i className="far fa-trash-alt"></i>
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="total-price">
                                <span className="f-left">Total:</span>
                                <span className="f-right">$239.9</span>
                              </div>
                            </li>
                            <li>
                              <div className="checkout-link">
                                <a href={empty_url}>Shopping Cart</a>
                                <a className="black-color" href={empty_url}>
                                  Checkout
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li> */}
                        <li className="header-btn">
                          <a href={empty_url} className="btn">
                            Adopt Here{" "}
                            <img src="assets/img/icon/w_pawprint.png" alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                {/* Mobile Menu  */}
                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times"></i>
                    </div>
                    <div className="nav-logo">
                      <a href="/">
                        <img src="assets/img/logo/logo.png" alt="" title="" />
                      </a>
                    </div>
                    <div className="menu-outer">
                      {/* Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                    </div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li>
                          <a href={empty_url}>
                            <span className="fab fa-twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href={empty_url}>
                            <span className="fab fa-facebook-square"></span>
                          </a>
                        </li>
                        <li>
                          <a href={empty_url}>
                            <span className="fab fa-pinterest-p"></span>
                          </a>
                        </li>
                        <li>
                          <a href={empty_url}>
                            <span className="fab fa-instagram"></span>
                          </a>
                        </li>
                        <li>
                          <a href={empty_url}>
                            <span className="fab fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop"></div>
              </div>
            </div>
          </div>
          <div
            className="header-shape"
            data-background="img/bg/header_shape.png"
          ></div>
        </div>
        <div
          className="search-popup-wrap"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="search-close">
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
          <div className="search-wrap text-center">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="title">... Search Here ...</h2>
                  <div className="search-form">
                    <form action="#">
                      <input
                        type="text"
                        name="search"
                        placeholder="Type keywords here"
                      />
                      <button className="search-btn">
                        <i className="fas fa-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
}

export default Menu;
