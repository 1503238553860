import React, { Fragment } from "react";
import Menu from './Menu';
import Footer from './Footer';

function Index() {
  let empty_url="";
  return (
  <Fragment> 
    <div className="header-top-area">
        <div className="container custom-container">
            <div className="row align-items-center">
                <div className="col-md-7">
                    <div className="header-top-left">
                        <ul>
                            <li>Call us: 747-800-9880</li>
                            <li><i className="far fa-clock"></i>Radno vrijeme: 7:00 am - 9:00 pm (Mon - Sun)</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="header-top-right">
                        <ul className="header-top-social">
                            <li className="follow">Follow :</li>
                            <li><a href={empty_url}><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href={empty_url}><i className="fab fa-twitter"></i></a></li>
                            <li><a href={empty_url}><i className="fab fa-instagram"></i></a></li>
                            <li><a href={empty_url}><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <Menu />
  <main>
      {/* <!-- slider-area --> */}
      <section className="slider-area">
          <div className="slider-active">
              <div className="single-slider slider-bg d-flex align-items-center" style={{ backgroundImage: `url("assets/img/slider/slider_bg01.jpg")`}}>
                  <div className="container custom-container">
                      <div className="row">
                          <div className="col-xl-5 col-lg-7 col-md-10">
                              <div className="slider-content">
                                  <div className="slider-title">
                                      <h2 className="title" data-animation="fadeInUpBig" data-delay=".2s" data-duration="1.2s">Best Friend <span>with</span> Happy Time</h2>
                                  </div>
                                  <div className="slider-desc">
                                      <p className="desc" data-animation="fadeInUpBig" data-delay=".4s" data-duration="1.2s">Human Shampoo on Dogs After six days of delirat, the jury found Hernandez guilty of first-degree murder</p>
                                  </div>
                                  <a href="dog-list.html" className="btn" data-animation="fadeInUpBig" data-delay=".6s" data-duration="1.2s">View More <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              {/* <div className="single-slider slider-bg d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/slider/slider_bg02.jpg")'}}>
                  <div className="container custom-container">
                      <div className="row">
                          <div className="col-xl-5 col-lg-7 col-md-10">
                              <div className="slider-content">
                                  <div className="slider-title">
                                      <h2 className="title" data-animation="fadeInUpBig" data-delay=".2s" data-duration="1.2s">Best Friend <span>with</span> Happy Time</h2>
                                  </div>
                                  <div className="slider-desc">
                                      <p className="desc" data-animation="fadeInUpBig" data-delay=".4s" data-duration="1.2s">Human Shampoo on Dogs After six days of delirat, the jury found Hernandez guilty of first-degree murder</p>
                                  </div>
                                  <a href="dog-list.html" className="btn" data-animation="fadeInUpBig" data-delay=".6s" data-duration="1.2s">View More <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}
          </div>
          <div className="slider-shape"><img src="assets/img/slider/slider_shape01.png" alt=""/></div>
          <div className="slider-shape shape-two"><img src="assets/img/slider/slider_shape02.png" alt=""/></div>
      </section>
      {/* <!-- slider-area-end --> */}

      {/* <!-- find-area --> */}
      <div className="find-area">
          <div className="container custom-container">
              <div className="row">
                  <div className="col-12">
                      <form action="#">
                          <div className="find-wrap">
                              <div className="location">
                                  <i className="flaticon-location"></i>
                                  <input type="text" value="Enter City, State. or Zip"/>
                              </div>
                              <div className="find-category">
                                  <ul>
                                      <li><a href="/usvoji-psa"><i className="flaticon-dog"></i> Pogledaj pse</a></li>
                                      <li><a href="/usvoji-macku"><i className="flaticon-happy"></i> Pogledaj mačke</a></li>
                                      {/* <li><a href="shop.html"><i className="flaticon-dove"></i> Find Your Birds</a></li> */}
                                  </ul>
                              </div>
                              {/* <div className="other-find">
                                  <div className="dropdown">
                                      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          Find Other Pets
                                      </button>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <a className="dropdown-item" href="shop.html">Find Your Dog</a>
                                          <a className="dropdown-item" href="shop.html">Find Your Cat</a>
                                          <a className="dropdown-item" href="shop.html">Find Your Birds</a>
                                      </div>
                                  </div>
                              </div> */}
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
      {/* <!-- find-area-end --> */}

      {/* <!-- counter-area --> */}
      <section className="counter-area counter-bg" style={{ backgroundImage: 'url("assets/img/bg/counter_bg.jpg")'}}>
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8">
                      <div className="counter-title text-center mb-65">
                          <h6 className="sub-title">Why Choose Us?</h6>
                          <h2 className="title">Best Service to Breeds Your Loved Dog Explore</h2>
                      </div>
                  </div>
              </div>
              <div className="row justify-content-center">
                  <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="counter-item">
                          <h2 className="count"><span className="odometer" data-count="73"></span>%</h2>
                          <p>dogs are first bred</p>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="counter-item">
                          <h2 className="count"><span className="odometer" data-count="259"></span>+</h2>
                          <p>Most dogs are first</p>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="counter-item">
                          <h2 className="count"><span className="odometer" data-count="39"></span>K</h2>
                          <p>Dog Breeding</p>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="counter-item">
                          <h2 className="count"><span className="odometer" data-count="45"></span>+</h2>
                          <p>Years Of History</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* <!-- counter-area-end --> */}

      {/* <!-- adoption-area --> */}
      <section className="adoption-area">
          <div className="container">
              <div className="row align-items-center align-items-xl-end justify-content-center">
                  <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
                      <div className="adoption-img">
                          <img src="assets/img/images/adoption_img.jpg" alt=""/>
                      </div>
                  </div>
                  <div className="col-xl-5 col-lg-6">
                      <div className="adoption-content">
                          <h2 className="title">Working For <br/> Dog <span>Adoption</span> Free, Happy Time</h2>
                          <p>The best overall dog DNA test is Embark Breed & Health Kit (view at Chewy), which provides you with a breed brwn and information.</p>
                          <a href="adoption.html" className="btn">Adoption <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                      </div>
                  </div>
              </div>
          </div>
          <div className="adoption-shape"><img src="assets/img/images/adoption_shape.png" alt=""/></div>
      </section>
      {/* <!-- adoption-area-end --> */}

      {/* <!-- breeds-services --> */}
      <section className="breeds-services pt-110 pb-110">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-9">
                      <div className="section-title text-center mb-65">
                          <div className="section-icon"><img src="assets/img/icon/pawprint.png" alt=""/></div>
                          <h5 className="sub-title">Service to Breeds</h5>
                          <h2 className="title">Most Popular Dog Breed</h2>
                          <p>The best overall dog DNA test is Embark Breed & Health Kit (view at Chewy), which provides you with a breed brwn and information Most dogs</p>
                      </div>
                  </div>
              </div>
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                      <div className="breed-services-active owl-carousel">
                          <div className="breed-services-item">
                              <div className="thumb">
                                  <img src="assets/img/images/breed_services_img01.jpg" alt=""/>
                              </div>
                              <div className="content">
                                  <h3 className="title"><a href="breeder-details.html">Golden Retriever</a></h3>
                              </div>
                          </div>
                          <div className="breed-services-item">
                              <div className="thumb">
                                  <img src="assets/img/images/breed_services_img02.jpg" alt=""/>
                              </div>
                              <div className="content">
                                  <h3 className="title"><a href="breeder-details.html">German Sharped</a></h3>
                              </div>
                          </div>
                          <div className="breed-services-item">
                              <div className="thumb">
                                  <img src="assets/img/images/breed_services_img03.jpg" alt=""/>
                              </div>
                              <div className="content">
                                  <h3 className="title"><a href="breeder-details.html">Siberian Husky</a></h3>
                              </div>
                          </div>
                          <div className="breed-services-item">
                              <div className="thumb">
                                  <img src="assets/img/images/breed_services_img04.jpg" alt=""/>
                              </div>
                              <div className="content">
                                  <h3 className="title"><a href="breeder-details.html">Bernes Mountain</a></h3>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="breed-services-info" style={{ backgroundImage: 'url(assets/img/bg/breed_services_bg.jpg")'}}>
                          <h5 className="sub-title">Dog Breeder</h5>
                          <h3 className="title">Available for Breed</h3>
                          <p>The best overall dog DNA test is Embark Breed & Health Kit (view at Chewy), which provid dogs</p>
                          <a href="dog-list.html" className="btn">More Pets <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                      </div>
                  </div>
              </div>
          </div>
          <div className="breed-services-shape"><img src="assets/img/images/breed_services_shape01.png" alt=""/></div>
          <div className="breed-services-shape shape-two"><img src="assets/img/images/breed_services_shape02.png" alt=""/></div>
      </section>
      {/* <!-- breeds-services-end --> */}

      {/* <!-- faq-area --> */}
      <section className="faq-area faq-bg">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-lg-6">
                      <div className="faq-img-wrap">
                          <img src="assets/img/images/faq_tv.png" className="img-frame" alt=""/>
                          <img src="assets/img/images/faq_img.png" className="main-img" alt=""/>
                          <a href="https://www.youtube.com/watch?v=XdFfCPK5ycw" className="popup-video"> </a>
                      </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="faq-wrapper">
                          <div className="section-title mb-35">
                              <h5 className="sub-title">FAQ Question</h5>
                              <h2 className="title">History & Family Adoption</h2>
                          </div>
                          <div className="accordion" id="accordionExample">
                              <div className="card">
                                  <div className="card-header" id="headingOne">
                                      <h2 className="mb-0">
                                          <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                              data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                              Working for dog adoption
                                          </button>
                                      </h2>
                                  </div>
                                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                      <div className="card-body">
                                          The best overall dog DNA test is embark breed & health Kit (view atths Chewy), which provides you with a breed brwn and ition on provides ancestors most dogs.
                                      </div>
                                  </div>
                              </div>
                              <div className="card">
                                  <div className="card-header" id="headingTwo">
                                      <h2 className="mb-0">
                                          <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                              data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                              Competitions & Awards
                                          </button>
                                      </h2>
                                  </div>
                                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                      <div className="card-body">
                                          The best overall dog DNA test is embark breed & health Kit (view atths Chewy), which provides you with a breed brwn and ition on provides ancestors most dogs.
                                      </div>
                                  </div>
                              </div>
                              <div className="card">
                                  <div className="card-header" id="headingThree">
                                      <h2 className="mb-0">
                                          <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                              data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                              The puppies are 3 months old
                                          </button>
                                      </h2>
                                  </div>
                                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                      <div className="card-body">
                                          The best overall dog DNA test is embark breed & health Kit (view atths Chewy), which provides you with a breed brwn and ition on provides ancestors most dogs.
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="faq-shape"><img src="assets/img/images/faq_shape.png" alt=""/></div>
      </section>
      {/* <!-- faq-area-end --> */}

      {/* <!-- brand-area --> */}
      <div className="brand-area pt-80 pb-80">
          <div className="container">
              <div className="row brand-active">
                  <div className="col-12">
                      <div className="brand-item">
                          <img src="assets/img/brand/brand_item01.png" alt="img"/>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="brand-item">
                          <img src="assets/img/brand/brand_item02.png" alt="img"/>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="brand-item">
                          <img src="assets/img/brand/brand_item03.png" alt="img"/>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="brand-item">
                          <img src="assets/img/brand/brand_item04.png" alt="img"/>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="brand-item">
                          <img src="assets/img/brand/brand_item05.png" alt="img"/>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="brand-item">
                          <img src="assets/img/brand/brand_item06.png" alt="img"/>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="brand-item">
                          <img src="assets/img/brand/brand_item03.png" alt="img"/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* <!-- brand-area-end --> */}

      {/* <!-- adoption-shop-area --> */}
      <section className="adoption-shop-area">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-9">
                      <div className="section-title text-center mb-65">
                          <div className="section-icon"><img src="assets/img/icon/pawprint.png" alt=""/></div>
                          <h5 className="sub-title">Meet the animals</h5>
                          <h2 className="title">Puppies Waiting for Adoption</h2>
                          <p>The best overall dog DNA test is Embark Breed & Health Kit (view at Chewy), which provides you with a
                              breed brwn and information Most dogs</p>
                      </div>
                  </div>
              </div>
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6">
                      <div className="adoption-shop-item">
                          <div className="adoption-shop-thumb">
                              <img src="assets/img/product/adoption_shop_thumb01.jpg" alt=""/>
                              <a href="shop-details.html" className="btn">Adoption <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                          </div>
                          <div className="adoption-shop-content">
                              <h4 className="title"><a href="shop-details.html">Mister Tartosh</a></h4>
                              <div className="adoption-meta">
                                  <ul>
                                      <li><i className="fas fa-cog"></i><a href={empty_url}>Siberian Husky</a></li>
                                      <li><i className="far fa-calendar-alt"></i> Birth : 2021</li>
                                  </ul>
                              </div>
                              <div className="adoption-rating">
                                  <ul>
                                      <li className="rating">
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                      </li>
                                      <li className="price">Total Price : <span>Free</span></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="adoption-shop-item">
                          <div className="adoption-shop-thumb">
                              <img src="assets/img/product/adoption_shop_thumb02.jpg" alt=""/>
                              <a href="shop-details.html" className="btn">Adoption <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                          </div>
                          <div className="adoption-shop-content">
                              <h4 className="title"><a href="shop-details.html">Charlie</a></h4>
                              <div className="adoption-meta">
                                  <ul>
                                      <li><i className="fas fa-cog"></i><a href={empty_url}>Golden Retriever</a></li>
                                      <li><i className="far fa-calendar-alt"></i> Birth : 2020</li>
                                  </ul>
                              </div>
                              <div className="adoption-rating">
                                  <ul>
                                      <li className="rating">
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                      </li>
                                      <li className="price">Total Price : <span>$30</span></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="adoption-shop-item">
                          <div className="adoption-shop-thumb">
                              <img src="assets/img/product/adoption_shop_thumb03.jpg" alt=""/>
                              <a href="shop-details.html" className="btn">Adoption <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                          </div>
                          <div className="adoption-shop-content">
                              <h4 className="title"><a href="shop-details.html">Alessia Max</a></h4>
                              <div className="adoption-meta">
                                  <ul>
                                      <li><i className="fas fa-cog"></i><a href={empty_url}>German Sherped</a></li>
                                      <li><i className="far fa-calendar-alt"></i> Birth : 2020</li>
                                  </ul>
                              </div>
                              <div className="adoption-rating">
                                  <ul>
                                      <li className="rating">
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                      </li>
                                      <li className="price">Total Price : <span>$29</span></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="adoption-shop-item">
                          <div className="adoption-shop-thumb">
                              <img src="assets/img/product/adoption_shop_thumb04.jpg" alt=""/>
                              <a href="shop-details.html" className="btn">Adoption <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                          </div>
                          <div className="adoption-shop-content">
                              <h4 className="title"><a href="shop-details.html">Canadian</a></h4>
                              <div className="adoption-meta">
                                  <ul>
                                      <li><i className="fas fa-cog"></i><a href={empty_url}>German Sherped</a></li>
                                      <li><i className="far fa-calendar-alt"></i> Birth : 2021</li>
                                  </ul>
                              </div>
                              <div className="adoption-rating">
                                  <ul>
                                      <li className="rating">
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                      </li>
                                      <li className="price">Total Price : <span>$39</span></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="adoption-shop-item">
                          <div className="adoption-shop-thumb">
                              <img src="assets/img/product/adoption_shop_thumb05.jpg" alt=""/>
                              <a href="shop-details.html" className="btn">Adoption <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                          </div>
                          <div className="adoption-shop-content">
                              <h4 className="title"><a href="shop-details.html">Entertainment</a></h4>
                              <div className="adoption-meta">
                                  <ul>
                                      <li><i className="fas fa-cog"></i><a href={empty_url}>Siberian Husky</a></li>
                                      <li><i className="far fa-calendar-alt"></i> Birth : 2021</li>
                                  </ul>
                              </div>
                              <div className="adoption-rating">
                                  <ul>
                                      <li className="rating">
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                      </li>
                                      <li className="price">Total Price : <span>Free</span></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="adoption-shop-item">
                          <div className="adoption-shop-thumb">
                              <img src="assets/img/product/adoption_shop_thumb06.jpg" alt=""/>
                              <a href="shop-details.html" className="btn">Adoption <img src="assets/img/icon/w_pawprint.png" alt=""/></a>
                          </div>
                          <div className="adoption-shop-content">
                              <h4 className="title"><a href="shop-details.html">Dangerous</a></h4>
                              <div className="adoption-meta">
                                  <ul>
                                      <li><i className="fas fa-cog"></i><a href={empty_url}>Golden Retriever</a></li>
                                      <li><i className="far fa-calendar-alt"></i> Birth : 2021</li>
                                  </ul>
                              </div>
                              <div className="adoption-rating">
                                  <ul>
                                      <li className="rating">
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                          <i className="fas fa-star"></i>
                                      </li>
                                      <li className="price">Total Price : <span>Free</span></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* <!-- adoption-shop-area-end --> */}

      {/* <!-- testimonial-area --> */}
      <section className="testimonial-area testimonial-bg">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-9">
                      <div className="section-title text-center mb-65">
                          <div className="section-icon"><img src="assets/img/icon/pawprint.png" alt=""/></div>
                          <h5 className="sub-title">Testimonials</h5>
                          <h2 className="title">Our Happy Customers</h2>
                          <p>The best overall dog DNA test is Embark Breed & Health Kit (view at Chewy), which provides you with a
                              breed brwn and information Most dogs</p>
                      </div>
                  </div>
              </div>
              <div className="row testimonial-active">
                  <div className="col-lg-6">
                      <div className="testimonial-item">
                          <div className="testi-avatar-thumb">
                              <img src="assets/img/images/testi_avatar01.png" alt=""/>
                          </div>
                          <div className="testi-content">
                              <p>“ The best overall dog DNA test Embark Breed & Health Kit (view at Chewy), which provides with a breed brwn and information ”</p>
                              <div className="testi-avatar-info">
                                  <h5 className="title">Alessia Cara</h5>
                                  <span>Googel CEO</span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="testimonial-item">
                          <div className="testi-avatar-thumb">
                              <img src="assets/img/images/testi_avatar02.png" alt=""/>
                          </div>
                          <div className="testi-content">
                              <p>“ The best overall dog DNA test Embark Breed & Health Kit (view at Chewy), which provides with a breed brwn and information ”</p>
                              <div className="testi-avatar-info">
                                  <h5 className="title">Alessia Cara</h5>
                                  <span>Googel CEO</span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="testimonial-item">
                          <div className="testi-avatar-thumb">
                              <img src="assets/img/images/testi_avatar01.png" alt=""/>
                          </div>
                          <div className="testi-content">
                              <p>“ The best overall dog DNA test Embark Breed & Health Kit (view at Chewy), which provides with a breed brwn and information ”</p>
                              <div className="testi-avatar-info">
                                  <h5 className="title">Alessia Cara</h5>
                                  <span>Googel CEO</span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-6">
                      <div className="testimonial-item">
                          <div className="testi-avatar-thumb">
                              <img src="assets/img/images/testi_avatar02.png" alt=""/>
                          </div>
                          <div className="testi-content">
                              <p>“ The best overall dog DNA test Embark Breed & Health Kit (view at Chewy), which provides with a breed brwn and information ”</p>
                              <div className="testi-avatar-info">
                                  <h5 className="title">Alessia Cara</h5>
                                  <span>Googel CEO</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* <!-- testimonial-area-end --> */}

      {/* <!-- blog-area --> */}
      <section className="blog-area pt-110 pb-60">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-9">
                      <div className="section-title text-center mb-65">
                          <div className="section-icon"><img src="assets/img/icon/pawprint.png" alt=""/></div>
                          <h5 className="sub-title">Our News</h5>
                          <h2 className="title">Latest News Update</h2>
                          <p>The best overall dog DNA test is Embark Breed & Health Kit (view at Chewy), which provides you with a
                              breed brwn and information Most dogs</p>
                      </div>
                  </div>
              </div>
              <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6">
                      <div className="blog-post-item mb-50">
                          <div className="blog-post-thumb">
                              <a href="blog-details.html"><img src="assets/img/blog/blog_post_thumb01.jpg" alt=""/></a>
                              <div className="blog-post-tag">
                                  <a href={empty_url}><i className="flaticon-bookmark-1"></i>Sharped</a>
                              </div>
                          </div>
                          <div className="blog-post-content">
                              <div className="blog-post-meta">
                                  <ul>
                                      <li><i className="far fa-user"></i><a href={empty_url}>Admin</a></li>
                                      <li><i className="far fa-bell"></i> Mar 10, 2021</li>
                                  </ul>
                              </div>
                              <h3 className="title"><a href="blog-details.html">Working For Dog Adoption</a></h3>
                              <p>The best overall dog test is Embark Breed & Health Kit view at Chewy.</p>
                              <a href="blog-details.html" className="read-more">Read More <img src="assets/img/icon/pawprint.png" alt=""/></a>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="blog-post-item mb-50">
                          <div className="blog-post-thumb">
                              <a href="blog-details.html"><img src="assets/img/blog/blog_post_thumb02.jpg" alt=""/></a>
                              <div className="blog-post-tag">
                                  <a href={empty_url}><i className="flaticon-bookmark-1"></i>Creative</a>
                              </div>
                          </div>
                          <div className="blog-post-content">
                              <div className="blog-post-meta">
                                  <ul>
                                      <li><i className="far fa-user"></i><a href={empty_url}>Admin</a></li>
                                      <li><i className="far fa-bell"></i> Mar 12, 2021</li>
                                  </ul>
                              </div>
                              <h3 className="title"><a href="blog-details.html">Dog Derived From an Ancient</a></h3>
                              <p>The best overall dog test is Embark Breed & Health Kit view at Chewy.</p>
                              <a href="blog-details.html" className="read-more">Read More <img src="assets/img/icon/pawprint.png" alt=""/></a>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                      <div className="blog-post-item mb-50">
                          <div className="blog-post-thumb">
                              <a href="blog-details.html"><img src="assets/img/blog/blog_post_thumb03.jpg" alt=""/></a>
                              <div className="blog-post-tag">
                                  <a href={empty_url}><i className="flaticon-bookmark-1"></i>Business</a>
                              </div>
                          </div>
                          <div className="blog-post-content">
                              <div className="blog-post-meta">
                                  <ul>
                                      <li><i className="far fa-user"></i><a href={empty_url}>Admin</a></li>
                                      <li><i className="far fa-bell"></i> Mar 12, 2021</li>
                                  </ul>
                              </div>
                              <h3 className="title"><a href="blog-details.html">Ten Dog Breeds are Noted</a></h3>
                              <p>The best overall dog test is Embark Breed & Health Kit view at Chewy.</p>
                              <a href="blog-details.html" className="read-more">Read More <img src="assets/img/icon/pawprint.png" alt=""/></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* <!-- blog-area-end --> */}

      {/* <!-- newsletter-area --> */}
      <div className="newsletter-area pb-110">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="newsletter-wrap">
                          <div className="newsletter-content">
                              <h2 className="title">Newsletter For</h2>
                              <p><span>*</span> Do Not Show Your Email.</p>
                          </div>
                          <div className="newsletter-form">
                              <form action="#">
                                  <input type="email" placeholder="Enter Your Email..."/>
                                  <button type="submit" className="btn">Subscribe</button>
                              </form>
                          </div>
                          <div className="newsletter-shape"><img src="assets/img/images/newsletter_shape01.png" alt=""/></div>
                          <div className="newsletter-shape shape-two"><img src="assets/img/images/newsletter_shape02.png" alt=""/></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* <!-- newsletter-area-end --> */}

  </main>
  <Footer />
  </Fragment>);
}

export default Index
