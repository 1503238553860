import React, { Fragment } from "react";

function Footer() {
  let empty_url = "";
  return (
    <Fragment>
      <footer>
        <div className="footer-top-area footer-bg">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-3">
                <div className="footer-widget">
                  <div className="f-logo">
                    <a href="index.html">
                      <img src="img/logo/logo.png" alt="" />
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                      The best overall dog DNA test Embark Breed & Health Kit
                      (view at Chewy) which provides overall dog you.
                    </p>
                  </div>
                  <div className="footer-contact">
                    <div className="icon">
                      <i className="fas fa-headphones"></i>
                    </div>
                    <div className="content">
                      <h4 className="title">
                        <a href="tel:0987654321">747-800-9880</a>
                      </h4>
                      <span>Call Now</span>
                    </div>
                  </div>
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href={empty_url}>
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href={empty_url}>
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href={empty_url}>
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href={empty_url}>
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="footer-widget">
                  <h4 className="fw-title">Our Policies</h4>
                  <div className="fw-link">
                    <ul>
                      <li>
                        <a href="contact.html">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">Terms and Conditions</a>
                      </li>
                      <li>
                        <a href="contact.html">Editorial Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">Return Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">IP Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">Grievance Redressal Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">Our Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="footer-widget">
                  <h4 className="fw-title">Our Services</h4>
                  <div className="fw-link">
                    <ul>
                      <li>
                        <a href="breeder.html">Our Breeder</a>
                      </li>
                      <li>
                        <a href="adoption.html">Our Adoption</a>
                      </li>
                      <li>
                        <a href="contact.html">Editorial Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">Return Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">Grievance Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">Redressal Policy</a>
                      </li>
                      <li>
                        <a href="contact.html">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="footer-widget">
                  <h4 className="fw-title">Instagram</h4>
                  <div className="fw-insta">
                    <ul>
                      <li>
                        <a href={empty_url}>
                          <img src="img/images/insta_img01.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href={empty_url}>
                          <img src="img/images/insta_img02.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href={empty_url}>
                          <img src="img/images/insta_img03.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href={empty_url}>
                          <img src="img/images/insta_img04.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href={empty_url}>
                          <img src="img/images/insta_img05.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href={empty_url}>
                          <img src="img/images/insta_img06.png" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-shape">
            <img src="img/images/footer_shape01.png" alt="" />
          </div>
          <div className="footer-shape shape-two">
            <img src="img/images/footer_shape02.png" alt="" />
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="copyright-text">
                  <p>Copyright &copy; 2021 Petco. All Rights Reserved.</p>
                </div>
              </div>
              <div className="col-md-4 d-none d-md-block">
                <div className="footer-lang">
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="img/icon/united-states.png" alt="" /> English
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton2"
                    >
                      <a className="dropdown-item" href="index.html">
                        <img src="img/icon/russia.png" alt="" />
                        Russia
                      </a>
                      <a className="dropdown-item" href="index.html">
                        <img src="img/icon//thailand.png" alt="" />
                        Thailand
                      </a>
                      <a className="dropdown-item" href="index.html">
                        <img src="img/icon/india.png" alt="" />
                        India
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
